// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-util-listing-jsx": () => import("/Users/henrique/development/talk-60-gatsby/uailugar/src/util/listing.jsx" /* webpackChunkName: "component---src-util-listing-jsx" */),
  "component---src-pages-404-jsx": () => import("/Users/henrique/development/talk-60-gatsby/uailugar/src/pages/404.jsx" /* webpackChunkName: "component---src-pages-404-jsx" */),
  "component---src-pages-index-jsx": () => import("/Users/henrique/development/talk-60-gatsby/uailugar/src/pages/index.jsx" /* webpackChunkName: "component---src-pages-index-jsx" */),
  "component---src-pages-page-2-jsx": () => import("/Users/henrique/development/talk-60-gatsby/uailugar/src/pages/page-2.jsx" /* webpackChunkName: "component---src-pages-page-2-jsx" */)
}

